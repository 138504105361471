<template>
  <v-col cols="2">
    <v-img src="../../assets/profile.svg" class="ma-auto mb-6" width="50%"></v-img>
    <p class="headline align-stretch">{{ info.user.name }}</p>
    <p class="subtitle"><span class="overline">Adresse</span><br/>
      {{ info.user.email }}
    </p>
    <v-divider/>
    <div class="text-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn small icon v-on="on" to="/profile/update">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </template>
        <span>Modifier</span>
      </v-tooltip>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'UserInfo',
  props: {
    info: Object,
  },
};
</script>
