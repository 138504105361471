<template>
<v-card tile class="ma-auto mt-4" width="86%">
  <v-card-title>Analyse transverse</v-card-title>
  <v-list>
    <v-list-item-group flat>
      <v-list-item to="/transversal">
        <v-list-item-avatar>
          <v-icon>mdi-apps</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Comparaison d'analyse</v-list-item-title>
          <v-list-item-subtitle>
            Visualiser plusieurs analyses simultanément
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/transversal/groups">
        <v-list-item-avatar>
          <v-icon>mdi-group</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Test de significativité</v-list-item-title>
          <v-list-item-subtitle>
            Significativité de la différence de valeur pour une donnée entre 2 groupes
           </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/transversal/interviews">
        <v-list-item-avatar>
          <v-icon>mdi-text</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Comparaison d'entretiens</v-list-item-title>
          <v-list-item-subtitle>
            Comparaison globale d'entretiens
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/transversal/prosody">
        <v-list-item-avatar>
          <v-icon>mdi-timer</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Prosodie</v-list-item-title>
          <v-list-item-subtitle>
            Comparaison des temps de réaction
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</v-card>
</template>

<script>
export default {
  name: 'TransversalMenu',
};
</script>
