<template>
  <v-row class="flex-row align-self-stretch">
    <UserInfo :info="{user}"/>
    <v-col cols="10">
      <v-row v-if="patients.length > 0">
        <v-col>
          <v-card tile class="ma-auto" width="86%">
            <v-card-title>Mes patients</v-card-title>
            <v-list>
              <v-subheader>{{ patients.length }} patient{{ s(patients) }}
                <v-spacer/>
                <v-btn small class="mt-4" color="light-blue" @click="dialog.add = !dialog.add" text>
                  <v-icon>mdi-plus</v-icon> Ajouter un patient
                </v-btn>

              </v-subheader>
              <v-list-item-group v-model="item" color="primary" flat>
                <v-list-item v-for="(patient, i) in patients" :key="i" :to="`${patient.link}`">
                  <v-list-item-avatar>
                    <v-icon>mdi-account-circle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small
                       v-if="patient.gender"
                       v-text="genderIcon[patient.gender-1]">)
                     </v-icon>
                      {{ patient.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span v-if="patient.number"> Numéro
                      <v-chip x-small
                        class="mr-5 ma-2"
                        color="pink" text-color="white">
                        {{ patient.number.toString().padStart(3, '0') }}
                      </v-chip></span>
                      Entretiens
                      <v-chip x-small class="mr-5 ma-2" color="teal" text-color="white">
                        {{ patient.nbInterviews }}
                      </v-chip>
                      Tests neurocognitifs
                      <v-chip x-small class="ma-2" color="light-blue" text-color="white">
                        {{ patient.nbNeurotests }}
                      </v-chip>
                      <v-spacer/><small>{{ patient.email }}</small>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action class="flex-row">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn small icon v-on="on" :to="`/patient/${patient._id}/update`">
                          <v-badge style="text-transform: none;"
                           content="Compléter le profil maintenant ?"
                           :value="showBadge && i == patients.length-1"
                           left transition="slide-x-transition" color="yellow darken-2" overlap>
                          <v-icon>mdi-pen</v-icon>
                          </v-badge>
                        </v-btn>
                      </template>
                      <span>Modifier</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <TransversalMenu/>
        </v-col>
      </v-row>
      <v-row class="justify-center d-flex" v-else>
        <div class="text-center">
          <v-img class="mx-auto mb-5" src="../assets/no-data.svg" max-width="200"></v-img>
          <p class="display-1 font-weight-thin grey--text text--lighten-1">
            Pas de patient à afficher
          </p>
          <v-btn small class="mt-4" color="light-blue" @click="dialog.add = !dialog.add" outlined>
            <v-icon small >mdi-plus</v-icon> Ajouter un patient
          </v-btn>
        </div>
      </v-row>
    </v-col>

    <v-dialog v-model="dialog.add" max-width="35%">
      <v-card>
        <v-card-title class="headline">Ajouter un patient</v-card-title>
        <v-col>
          <FormAlert :info="{alert}"></FormAlert>
        </v-col>
        <v-form ref="form" v-on:submit.prevent="add()" v-model="valid">
        <v-card-text>
            <v-text-field
            label="Prénom et nom"
            name="name"
            prepend-icon="mdi-account"
            type="text"
            :counter="64"
            :rules="this.rules.name"
            v-model="newPatient.name"/>
            <v-text-field
            label="Email"
            name="email"
            prepend-icon="mdi-at"
            type="text"
            v-model="newPatient.email"
            :rules="this.rules.email"
            />
      </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialog.add = !dialog.add">Annuler</v-btn>
          <v-btn type="submit" :disabled="!valid" color="green darken-1" outlined>
            Ajouter
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import MixinUsers from '@/mixins/users';
import MixinPatients from '@/mixins/patients';
import MixinInterviews from '@/mixins/interviews';
import MixinNeurotests from '@/mixins/neurotests';
import UserInfo from '@/components/user/Info.vue';
import FormAlert from '@/components/common/forms/Alert.vue';
import TransversalMenu from '@/components/transversal/Menu.vue';

export default {
  name: 'Profile',
  mixins: [MixinUsers, MixinPatients, MixinInterviews, MixinNeurotests],
  components: { UserInfo, FormAlert, TransversalMenu },
  data: () => ({
    valid: false,
    dialog: { add: false },
    showBadge: false,
    newPatient: { name: '', email: '' },
    patients: [],
    user: {},
    genderIcon: [
      'mdi-gender-female',
      'mdi-gender-male',
      'mdi-gender-transgender',
    ],
    item: 0,
  }),

  async mounted() {
    // Get patients list from server
    this.patients = await this.getPatientList();
    this.user = await this.getUserInfo();

    this.patients.forEach(async (item) => {
      const patient = item;

      // eslint-disable-next-line
      patient.id = item._id;

      // Set link to patient page
      patient.link = `/patient/${patient.id}`;

      // Count interviews and neurotests for this patient
      patient.nbInterviews = await this.countInterviews(patient.id);
      patient.nbNeurotests = await this.countNeurotests(patient.id);

      // FIXME : won't update previous count without this...
      this.$forceUpdate();
    });
  },

  methods: {
    hideBadge() {
      this.showBadge = false;
    },

    async add() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const patient = await this.createPatient(this.newPatient);

      if (patient) {
        // eslint-disable-next-line
        patient.id = patient._id;

        // Set link to patient page
        patient.link = `/patient/${patient.id}`;

        patient.nbInterviews = 0;
        patient.nbNeurotests = 0;

        this.patients.push(patient);
        this.dialog.add = false;
        this.showBadge = true;
        setTimeout(this.hideBadge, 5000);
      }

      this.newPatient = { name: '', email: '' };
    },
  },
};
</script>
